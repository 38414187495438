:root {
  --colorPrimario: #FFC629;
  --colorSecundario: #DB251B;
  --colorTerciario: #282530;
  --colorCuarto: #453F53;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body{
  background-color: var(--colorPrimario);
}
.buttonCuarto{
  color:#fff;
  background-color: var(--colorCuarto);
}

.containerApp {
  height: auto;
}